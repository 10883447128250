<template>
    <section class="">
        <svg-content />
            <!-- Developer Intro -->
            <div class="dev-intro  d-flex flex-column gap-md-5 gap-4 justify-content-center align-items-center">
                <!-- Role Intro -->
                <div class="d-none d-lg-flex justify-content-center align-items-center">
                    <div class="prof-role d-flex gap-3 justify-content-center align-items-center">
                        <i :class="profIcon" class="fa-solid"></i>
                        <div ref="profContainer">{{prof}}.</div>
                        <svg viewBox="0 0 150 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="highlight-line">
                            <path d="M31.772 1.74797C23.064 2.96589 1.42893 3.43175 0.558874 5.21238C-3.90119 10.3737 19.106 5.22948 42.1113 3.88018C44.6949 3.49632 91.2227 3.88018 93.7717 3.88018C109.281 3.88018 122.205 6.16589 142.883 7.37671C145.468 9.1252 155.843 6.99272 145.504 3.49622C144.424 3.57664 101.562 0 80.8834 0H80.874C55.0353 0 55.0325 0 31.772 1.74797Z" />
                        </svg>
                        <!-- <img :src="highlightLine" class="highlight-line" alt=""> -->
                    </div>
                </div>

                <!-- Name -->
                <div class="name">
                    {{ name }}
                </div>
                
                    <div class="quote">
                        <Quote :size="16" class="me-2"/><span>Coding is the art of turning coffee into code.</span>
                    </div>

            </div>

    </section>
</template>

<script setup>
import { ref } from 'vue';
// import highlightLine from '@/assets/svg/highlight-line.svg';
import SvgContent from './SvgContent.vue';
import { Quote } from 'lucide-vue-next';



const name = ref('JAY PRAJAPATI');
const prof = ref('Frontend Developer');
const profIcon = ref('fa-code');

const roles = ["Frontend Developer", "UI/UX Designer", "Freelancer", "Full-stack Developer"]
const icons = ["fa-code", "fa-bezier-curve", "fa-handshake", "fa-database"]
const profContainer = ref(null)
let currentIndex = 1;

function changeText() {
    prof.value= roles[currentIndex];
    profIcon.value = icons[currentIndex];
    currentIndex = (currentIndex + 1) % roles.length;
}

setInterval(changeText, 3000);
</script>

<style scoped>
svg.highlight-line path {
    fill: var(--highlight-text-color) !important;
}
</style>