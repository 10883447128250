<template>
    <div class="d-flex justify-content-between align-items-center header">
        <div class="d-flex d-lg-none justify-content-center align-items-center" v-if="!logoVisible">
                    <div class="prof-role d-flex gap-3 justify-content-center align-items-center">
                        <i :class="profIcon" class="fa-solid"></i>
                        <div ref="profContainer">{{prof}}.</div>
                        <!-- <img :src="highlightLine" class="highlight-line" alt=""> -->
                        <svg viewBox="0 0 150 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="highlight-line">
                            <path d="M31.772 1.74797C23.064 2.96589 1.42893 3.43175 0.558874 5.21238C-3.90119 10.3737 19.106 5.22948 42.1113 3.88018C44.6949 3.49632 91.2227 3.88018 93.7717 3.88018C109.281 3.88018 122.205 6.16589 142.883 7.37671C145.468 9.1252 155.843 6.99272 145.504 3.49622C144.424 3.57664 101.562 0 80.8834 0H80.874C55.0353 0 55.0325 0 31.772 1.74797Z" />
                        </svg>
                    </div>
                </div>
        <div class="name d-lg-block" :style="{ visibility: logoVisible ? 'visible' : 'hidden', display: !logoVisible ? 'none' : 'block' }">
            {{ name }}
            <!-- <img :src="highlightLine" class="highlight-line" alt=""> -->
            <svg viewBox="0 0 150 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="highlight-line">
                <path d="M31.772 1.74797C23.064 2.96589 1.42893 3.43175 0.558874 5.21238C-3.90119 10.3737 19.106 5.22948 42.1113 3.88018C44.6949 3.49632 91.2227 3.88018 93.7717 3.88018C109.281 3.88018 122.205 6.16589 142.883 7.37671C145.468 9.1252 155.843 6.99272 145.504 3.49622C144.424 3.57664 101.562 0 80.8834 0H80.874C55.0353 0 55.0325 0 31.772 1.74797Z" />
            </svg>
        </div>
        <div class="nav-btn-wrapper expanded-nav-bar">
            <router-link :to="{ name: 'Home' }" :style="{ visibility: logoVisible ? 'visible' : 'hidden' }" class="nav-btn" :class="currentRoute == 'Home' && 'active-nav-link'">
                <!-- <button > -->
                    <House />
                <!-- </button> -->
            </router-link>
            <router-link :to="{ name: 'Work' }" class="nav-btn" :class="currentRoute == 'Work' && 'active-nav-link'">
                <!-- <button > -->
                    <LaptopMinimalCheck /> Work
                <!-- </button> -->
            </router-link>
            <router-link :to="{ name: 'Skills' }" class="nav-btn" :class="currentRoute == 'Skills' && 'active-nav-link'">
                <!-- <button > -->
                    <CodeXml /> Skills
                <!-- </button> -->
            </router-link>
            <router-link :to="{ name: 'Projects' }" class="nav-btn" :class="currentRoute == 'Projects' && 'active-nav-link'">
                <!-- <button > -->
                    <FolderGit2 /> Projects
                <!-- </button> -->
            </router-link>
            <router-link :to="{ name: 'Achievements' }" class="nav-btn" :class="currentRoute == 'Achievements' && 'active-nav-link'">
                <!-- <button > -->
                    <Award /> Achievements
                <!-- </button> -->
            </router-link>
            <button @click="toggleDarkMode(false)" class="toggle-modes">
                <span v-if="isLightMode"><Sun :size="20" :stroke-width="3"/></span>
                <span v-else><Moon :size="20" :stroke-width="3"/></span>
                <!-- {{ isLightMode ? '🌞 Light' : '🌙 Dark' }} -->
            </button>
            <!-- <router-link :to="{ name: 'Activity' }">
                <button class="nav-btn" :class="currentRoute == 'Activity' && 'active-nav-link'">
                    <Activity /> Activity
                </button>
            </router-link> -->

        </div>
        <router-link class="mobile-nab-bar" :to="{ name: 'Home' }" :style="{ visibility: logoVisible ? 'visible' : 'hidden' }">
                <button class="nav-btn" :class="currentRoute == 'Home' && 'active-nav-link'"  @click="toggleNavDropdown(false)">
                    <House />
                </button>
            </router-link>
        <div class="position-relative mobile-nab-bar">
            <button class="nav-btn active-nav-link" @click="toggleNavDropdown()">
                {{ currentRoute }}
                <ChevronDown />
            </button>
            <div class="position-absolute nav-links-dropdown" v-if="isNavDropdownOpen">
                <router-link :to="{ name: 'Work' }">
                    <button class="nav-btn my-1" :class="currentRoute == 'Work' && 'active-nav-link'" @click="toggleNavDropdown(false)">
                        <LaptopMinimalCheck /> Work
                    </button>
                </router-link>
                <router-link :to="{ name: 'Skills' }">
                    <button class="nav-btn my-1" :class="currentRoute == 'Skills' && 'active-nav-link'" @click="toggleNavDropdown(false)">
                        <CodeXml /> Skills
                    </button>
                </router-link>
                <router-link :to="{ name: 'Projects' }">
                    <button class="nav-btn my-1" :class="currentRoute == 'Projects' && 'active-nav-link'" @click="toggleNavDropdown(false)">
                        <FolderGit2 /> Projects
                    </button>
                </router-link>
                <router-link :to="{ name: 'Achievements' }">
                    <button class="nav-btn my-1" :class="currentRoute == 'Achievements' && 'active-nav-link'" @click="toggleNavDropdown(false)">
                        <Award /> Achievements
                    </button>
                </router-link>
                <hr />
                <button @click="toggleDarkMode(true)" class="nav-btn  toggle-modes">
                <span v-if="isLightMode"><Sun :size="16" :stroke-width="3"/> Light Mode</span>
                <span v-else><Moon :size="16" :stroke-width="3"/> dark mode</span>
                <!-- {{ isLightMode ? '🌞 Light' : '🌙 Dark' }} -->
            </button>
                <!-- <button @click="toggleDarkMode">
                {{ isLightMode ? '🌞 Light' : '🌙 Dark' }}
            </button> -->
                <!-- <router-link :to="{ name: 'Activity' }">
                    <button class="nav-btn my-1" :class="currentRoute == 'Activity' && 'active-nav-link'" @click="toggleNavDropdown(false)">
                        <Activity /> Activity
                    </button>
                </router-link> -->
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { LaptopMinimalCheck } from 'lucide-vue-next';
import { CodeXml } from 'lucide-vue-next';
import { Award } from 'lucide-vue-next';
import { ChevronDown } from 'lucide-vue-next';
import { FolderGit2 } from 'lucide-vue-next';
import { Moon } from 'lucide-vue-next';
import { Sun } from 'lucide-vue-next';
// import { Activity } from 'lucide-vue-next';
import { House } from 'lucide-vue-next';
// import highlightLine from '@/assets/svg/highlight-line.svg';


const name = ref('JAY PRAJAPATI');

const isLightMode =  ref(false);

function toggleDarkMode(isFromMobileDropdown) {
      isLightMode.value = !isLightMode.value
      document.body.classList.toggle('light-mode', isLightMode.value)
      if(isFromMobileDropdown) {
        toggleNavDropdown(false);
      }
      // Save to localStorage
    //   localStorage.setItem('theme', this.isLightMode ? 'dark' : 'light')
    }

const isNavDropdownOpen = ref(false);

function toggleNavDropdown(val = null) {
    if (val!== null) {
        isNavDropdownOpen.value = val;
    } else {
        isNavDropdownOpen.value =!isNavDropdownOpen.value;
    }
    // isNavDropdownOpen.value =!isNavDropdownOpen.value;
}

const route = useRoute();
const logoVisible = computed(() => {
    return (route.name !== 'Home')
});

const currentRoute = computed(() => {
    return route.name
});

// watch(() => logoVisible, (newVal) => {
//   logoVisible.value = newVal;
// });

const prof = ref('Frontend Developer');
const profIcon = ref('fa-code');
let currentIndex = 1;

const roles = ["Frontend Developer", "UI/UX Designer", "Freelancer", "Full-stack Developer"]
const icons = ["fa-code", "fa-bezier-curve", "fa-handshake", "fa-database"]
const profContainer = ref(null);

function changeText() {
    prof.value = roles[currentIndex];
    profIcon.value = icons[currentIndex];
    currentIndex = (currentIndex + 1) % roles.length;
}
setInterval(changeText, 3000);
</script>

<style scoped lang="scss">
.toggle-modes {
    padding: 6px;
    font-size: 14px;
    font-weight: 600;
    color: var(--theme-button-color);
    &:hover {
        background: var(--active-button-bg-color);
        
        border-radius: 5px;
    }
}

hr {
    margin-inline: 5px;
    margin-block: 10px;
}

svg.highlight-line path {
    fill: var(--highlight-text-color) !important;
}
</style>