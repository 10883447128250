<template>
    <!-- <div class="d-flex justify-content-center d-md-none my-4">
        <span class="section-title">PROJECTS</span>
    </div> -->
    <!-- <div class="d-flex justify-content-center align-items-center w-100"> -->
        <div class="projects-wrapper  mt-16">
        <div v-for="project in projects" :key="project.id" class="project-card-wrapper flex-column flex-lg-row">
                <img :src="getThumbnailPath(project.thumbnail)" alt="" class="project-image">
                <div class="project-details">
                    <div class="project-title-subtitle">
                        <div class="project-subtitle">
                            <div>{{ project.about }}</div>
                            <div class="year">{{ project.year }}</div>
                        </div>
                        <div class="d-flex justify-content-start gap-2 align-items-center">
                        <div class="project-title">{{ project.title }}</div>
                        <a :href="project.links.liveDemo" v-if="project.links.liveDemo" class="live-link link"> <Radio :size="16" /> Live </a>
                        </div>
                    </div>

                    <div class="project-description">
                        <div class="desc">{{ project.desc }}</div>
                        <div class="year-and-links">
                            <!-- <div class="year">{{ project.year }}</div> -->
                            <div class="links">
                                <!-- <a :href="project.links.liveDemo" class="live-link link"> <Radio :size="16" /> Live </a> -->
                                <a :href="project.links.github" v-if="project.links.github" class="github-link link"> <Github :size="16" /> GitHub </a>
                                <a :href="project.links.youtube" v-if="project.links.youtube" class="demo-link link"> <Youtube :size="16"/> Demo </a>
                            </div>
                        </div>
                        <div class="tech-cards">
                            <div v-for="tech in project.techUsed" :key="tech.name" class="tech-card"><img :src="getLogoPath(tech)" alt="" width="20" height="20"> {{ tech }}</div>
                        </div>
                        
                    </div>
                </div>
                
                
                
            </div>
        
    </div>
    <!-- </div> -->
    
</template>

<script setup>
import { Youtube } from 'lucide-vue-next';
import { Github } from 'lucide-vue-next';
// import { ExternalLink } from 'lucide-vue-next';
import { Radio } from 'lucide-vue-next';
const projects = [
    {
        id: 0,
        thumbnail: "CodeHive2.svg",
        title: "CodeHive",
        about: "Live Code Collaboration Tool",
        techUsed: ["React", "MongoDB", "Node", "WebSockets"],
        desc: "A real-time collaborative coding platform for seamless co-editing, chat, and code execution. Built with React + SocketIO, it features synced code/chat, secure rooms, Docker-isolated execution, and role management.",
        year: '2025',
        links: {
            github: "https://github.com/jayyprajapati/code-collaboration-tool",
            liveDemo: "https://codehive.jayprajapati.me/",
            youtube: "https://youtu.be/pU95yq8DE_8",
        }
    },
    {
        id: 1,
        thumbnail: "TradeTactix2.svg",
        title: "TradeTactix",
        about: "Stock Analysis Platform",
        techUsed: ["Vue", "MongoDB", "Redis", "Node", "D3"],
        desc: "A Vue.js web app for technical analysis of Indian stocks, with a Node.js and Express backend. Redis caches historical data, MongoDB stores company info, and SMA/RSI charts are visualized using Observablehq Plot.",
        year: 2024,
        links: {
            github: "https://github.com/jayyprajapati/stocks-analysis",
            liveDemo: "https://tradetactix.jayprajapati.me/",
            youtube: "https://youtu.be/LFJjjmQ198E?si=yKVysjMLZvNLCACk",
        }
    },
    {
        id: 2,
        thumbnail: "CloudNotes2.svg",
        title: "CloudNotes",
        about: "Note Management Application",
        techUsed: ["React", "MongoDB", "Redux", "Node"],
        desc: 'A note taking application which requires user credentials and allow users to add new note, edit/delete existing ones and search specific notes.',
        year: 2022,
        links: {
            github: "https://github.com/jayyprajapati/cloudnotes",
            liveDemo: "https://cloudnotes.jayprajapati.me/",
            youtube: "https://youtu.be/Qy5VH2wzzEg?si=y7kJ4re2hzt7RGFl",
        }
    },
    {
        id: 3,
        title: "NewsBee",
        about: "News Platform",
        thumbnail: "NewsBees2.svg",
        techUsed: ["React"],
        desc: 'A news fetch application which renders all the latest news on a single page with infinite scrolling. It also includes category specific news filters.',
        year: 2022,
        links: {
            github: "https://github.com/jayyprajapati/React-NewsBee",
            liveDemo: "",
            youtube: ""
        }
    },
    {
        id: 4,
        thumbnail: "TinyBasket2.svg",
        title: "TinyBasket",
        about: "E-commerce Platform",
        techUsed: ["Ejs", "MongoDB", "Node", "Express"],
        desc: 'Grocery E-commerce website where logged in user can explore/search the products in categorized manner. User can add products to cart and complete the payment using checkout option.',
        year: 2020,
        links: {
            github: "https://github.com/jayyprajapati/Online-Grocery-Store",
            liveDemo: "",
            youtube: "https://youtu.be/z5sIunlf5Hs?si=f7cMlCtH299TChyv"
        }
    },
]
function getLogoPath(logo) {
    return require(`@/assets/svg/skills/${logo}.svg`);
}

function getThumbnailPath(img) {
    return require(`@/assets/images/thumbnails/${img}`);
}
</script>

<style scoped lang="scss">

.project-title-subtitle {
    margin-left: 120px;
    font-weight: 600;

    .project-subtitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 10px;
        color: var(--secondary-text-color);

        .year {
            padding-inline: 5px;
            padding-block: 2px;
            border-radius: 2px;
            // background: #5c667a;
        }
    }

    .project-title {
        font-size: 24px;
        color: var(--title-text-color);
    }
}

.project-card-wrapper {
    
    // max-width: 350px;
    // height: 450px;
    position: relative;
    border-radius: 10px;
    display: flex;
    gap: 1rem;
    // justify-content: center;
    // align-items: center;
    // background: var(--background-dark-color);
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border: 1px solid #adb5bd;
    border-radius: 30px;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    // color: #fff;
    padding: 20px;
    width: 100%;
    max-width: 1100px;

    &:hover {
        border-color: var(--highlight-text-color);
        box-shadow: rgba(255, 255, 255, 0.35) 0px 5px 15px;
        scale: 1.01;
        transition: all 0.2s ease-in;
    }
}

.thumbnail {
    width: 200px;
    // height: 140px;
    // border-radius: 5px;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
// .project-title {
//     font-weight: 600;
//     // color: var(--highlight-text-color);
//     color: var(--highlight-text-color);
//     font-size: 20px;
//     text-align: left;
//     padding-block: 5px;

    
// }

.year-and-links {
    display: flex;
    justify-content: end;
    align-items: center;

    // margin-top: 10px;

    .year {
        border-radius: 4px;
        font-size: 12px;
        color: var(--secondary-text-color);
        font-weight: 600;
        width: fit-content;
        // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        border: 2px solid var(--border-light-grey-color);
        padding-inline: 8px;
    }

    .links {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 12px;
        color: var(--secondary-text-color);
        font-weight: 600;


    }
}

.tech-cards {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    font-size: 12px;
    font-weight: 600;
    gap: 8px;
    color: var(--secondary-text-color);

    .tech-card {
        // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        padding-inline: 8px;
        padding-block: 2px;
        border-radius: 100px;
        // background: #f3f3f4;
        background: rgba(255,255,255, 0.2);
        // color: black;
    }
}
i {
    &:hover {
        color: var(--highlight-text-color)
    }
}

.project-desc {
    font-size: 12px;
    text-align: justify;
    // font-style: italic;
    font-weight: 500;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    // height: 100px;
}

.project-image {
    position: absolute;
    width: 120px;
    top: -30px;
    left: 20px;
    background: var(--bg-color);
    padding-inline: 10px;
    // margin-inline: 10px;
}

.project-year {
    border: 1px solid var(--border-light-grey-color);
    border-radius: 50px;
    padding-block: 3px;
    padding-inline: 15px;
    font-size: 12px;
    font-weight: 600;
    width: fit-content;
    margin-bottom: 10px;
}

.project-about {
    font-size: 12px;
    font-weight: 600;
    color: #5c667a;
    text-align: left;
}

.project-image-section {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-description {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .desc {
        font-size: 12px;
        font-weight: 500;
        color: var(--secondary-text-color);
    }

}


.project-links a {
    background: #e9ecef;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 8px;
    font-size: 12px;
    color: #495057 !important;
    font-weight: 600;

    &:hover {
        // background: #495057;
        color: #fff !important;
    }

    
    // color: #fff;
}

.link {
    padding-inline: 8px;
    padding-block: 2px;
    font-size: 12px;
    color: var(--secondary-text-color) !important;
    font-weight: 600;
}

.live-link {
    color: var(--project-live-button-text-color) !important;
    &:hover {
        background: linear-gradient(45deg, rgba(37, 211, 102, 0.8), rgba(7, 94, 84, 0.8));
        color: #fff !important;
        
        border-radius: 100px;
    }
}

.live-demo:hover {
        background: linear-gradient(45deg, rgba(37, 211, 102, 1), rgba(7, 94, 84, 1));
        color: #fff !important;
        border-radius: 100px;
    }

    .demo-link:hover {
        background: linear-gradient(45deg, rgba(255, 0, 0, 1), rgba(200, 0, 0, 1));
        color: #fff !important;
        border-radius: 100px;
    }

    .github-link:hover {
        background-image: linear-gradient(45deg, 
    rgba(68, 68, 68, 1),
    rgba(24, 23, 23, 1)    /* GitHub brand dark gray */
  );
  color: #fff !important;
        border-radius: 100px;
    }

.project-details-section {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media only screen and (max-width: 900px) {
    width: 100%;
  }
}
</style>