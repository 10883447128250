<template>
    <footer class="mt-5 mb-2">
        <div class="d-flex flex-column flex-lg-row gap-4 justify-content-between align-items-center">
                <div class="nav-btn-wrapper">
                    <!-- <button > -->
                        <a class="nav-btn bottom-nav-btn linkedin-button" href="https://www.linkedin.com/in/jay-prajapati-614180191/" target="_blank"><Linkedin :size="14"/> LinkedIn</a>
                    <!-- </button> -->
                    <!-- <button > -->
                        <a class="nav-btn bottom-nav-btn github-button" href="https://github.com/jayyprajapati" target="_blank"><Github :size="14"/> GitHub</a>
                    <!-- </button> -->
                    <!-- <button > -->
                        <a class="instagram-button nav-btn bottom-nav-btn" href="https://www.instagram.com/jayy.prajapati" target="_blank"><Instagram :size="14"/> Instagram</a>
                    <!-- </button> -->
                    <!-- <button > -->
                        <a class="gmail-button nav-btn bottom-nav-btn" href="mailto:jay.prajapati5717@gmail.com" target="_blank"><Mail :size="14"/> Contact</a>
                    <!-- </button> -->
                </div>
                    <div class="copyright"><Copyright :size="14" /> 2025. All Rights Reserved.</div>

                <div class="d-flex justify-content-between align-items-center gap-4 connect-section">
                    <!-- <button > -->
                        <a class="nav-btn" :href="resumeLink" target="_blank"><FileText :size="16"/> Resume </a>
                <!-- </button> -->
                    <button class="nav-btn connect-button" @click.prevent="toggleModal">
                         <MessageSquareDot :size="16" /> Drop a message!
                    </button>
                    
                </div>
            </div>
    </footer>
    <div class="resume-modal-overlay" v-if="isContactMeOpen">
        <div class="resume-modal p-4">
            <div class="mb-3">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="resume-title">CONTACT ME</div>
                    <i class="fa-solid fa-circle-xmark cursor-pointer" @click="toggleModal"></i>
                </div>
                
                <div class="resume-sub-title">Let's discuss and work together to make something exciting... </div>
            </div>

            <form @submit.stop.prevent="handleSubmit">
                <div class="d-md-flex gap-3">
                    <v-text-field label="Full Name" variant="outlined" v-model="$v.form.name.$model"  :error-messages="nameErrors"></v-text-field>
                    <v-text-field label="Email Address" variant="outlined" v-model="$v.form.email.$model" :error-messages="emailErrors"></v-text-field>
                </div>
                

            <v-text-field label="Subject" variant="outlined" v-model="$v.form.subject.$model" :error-messages="subjectErrors" ></v-text-field>
            <v-textarea label="Message" variant="outlined" v-model="$v.form.message.$model" :error-messages="messageErrors"></v-textarea>

            <div>
                <div v-if="loading">
                    <svg class="loading" viewBox="25 25 50 50">
                        <circle r="20" cy="50" cx="50"></circle>
                    </svg>
                </div>
            <button v-else type="submit" class="submit-btn px-3 py-1">Send <i class="ms-1 fa-solid fa-arrow-right-long"></i></button>
            </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { FileText } from 'lucide-vue-next';
import { Linkedin } from 'lucide-vue-next';
import { Github } from 'lucide-vue-next';
import { Instagram } from 'lucide-vue-next';
import { MessageSquareDot } from 'lucide-vue-next';
import { Copyright } from 'lucide-vue-next';
import { Mail } from 'lucide-vue-next';
import useVuelidate from '@vuelidate/core';
import { required, minLength, email } from '@vuelidate/validators';
// import sectionDivider from '@/assets/svg/section-divider.svg'

const form = ref({
  name: '',
  email: '',
  subject: '',
  message: ''
});

const rules = {
  form: {
    name: { required, minLength: minLength(3) },
    email: { required, email },
    subject: {required, minLength: minLength(3)},
    message: {required, minLength: minLength(3)}
  },
};
// Initialize Vuelidate
const $v = useVuelidate(rules, { form });
const loading = ref(false);

const nameErrors = computed(() => {
//   $v.value.form.name.$touch();
  const errors = [];
        if (!$v.value.form.name.$dirty) return errors;
        $v.value.form.name.required.$invalid && errors.push('Name is required!');
        $v.value.form.name.minLength.$invalid && errors.push('At least 3 chars required!');
        return errors;
});
const emailErrors = computed(() => {
    const errors = [];
        if (!$v.value.form.email.$dirty) return errors;
        $v.value.form.email.email.$invalid && errors.push('Invalid Email!');
        $v.value.form.email.required.$invalid && errors.push('Email is required!');
        return errors;
});

const subjectErrors = computed(() => {
    const errors = [];
        if (!$v.value.form.subject.$dirty) return errors;
        $v.value.form.subject.required.$invalid && errors.push('Subject is required!');
        $v.value.form.subject.minLength.$invalid && errors.push('At least 3 chars required!');
        return errors;
});

const messageErrors = computed(() => {
    const errors = [];
        if (!$v.value.form.message.$dirty) return errors;
        $v.value.form.message.required.$invalid && errors.push('Message is required!');
        $v.value.form.message.minLength.$invalid && errors.push('At least 3 chars required!');
        return errors;
});

const isContactMeOpen = ref(false)


function toggleModal() {
    isContactMeOpen.value = !isContactMeOpen.value
}


async function handleSubmit() {
    $v.value.$touch();

    if (!$v.value.$invalid) {
    // Form is valid, proceed with submission
    console.log('Form Submitted:', form.value);
    loading.value = true
    const formData = {
        name: form.value.name,
        email: form.value.email,
        subject: form.value.subject,
        message: form.value.message
    }
    console.log(formData);
    
    const resp = await fetch('https://email-service-drab-six.vercel.app/submit-form', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    })

    console.log(resp)
    if(resp.ok) console.log("Form submitted successfully!");
    else console.log('Oops something went wrong!');
    loading.value = false;
  } else {
    // Handle validation errors
    console.log('Validation Errors:', $v.value.$errors);
  }

    
    
    
}

const resumeLink = process.env.VUE_APP_RESUME_LINK;
</script>