<template>
    <div class="d-flex skill-wrapper justify-content-md-center align-items-center">
        <div class="skills-grid">
            <div class="frontend p-3 d-flex justify-content-around align-items-center gap-4">
                <div class="skill">
                    <img :src="getLogoPath('Vue')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center ">Vue</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('React')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center ">React</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('Js')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center ">Javascript</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('Typescript')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center ">Typescript</div> 
                </div>
            </div>
            <div class="backend p-3">
                <div class="skill">
                    <img :src="getLogoPath('CSharp')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center">C#</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('Dotnet')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center">.NET</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('MongoDB')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center">MongoDB</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('sqlserver')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center">MS SQL Server</div> 
                </div>
            </div>
            <div class="prog-lang p-3 d-flex justify-content-around align-items-center gap-4">
                <div class="skill">
                    <img :src="getLogoPath('Python')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center">Python</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('Java')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center">Java</div> 
                </div>
            </div>
            <div class="skills-title p-3 d-flex justify-content-center align-items-center gap-4">
                <i class="fa-solid fa-code me-2"></i> <div class="title">Skills</div> 
            </div>
            <div class="html-css p-3 d-flex justify-content-around align-items-center gap-4">
                <div class="skill">
                    <img :src="getLogoPath('Html5')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center">HTML</div> 
                </div>
                <div class="skill"> 
                    <img :src="getLogoPath('Css3')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center">CSS</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('Scss')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center">SASS</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('Tailwind')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center">Tailwind</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('Less')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center">Less CSS</div> 
                </div>
            </div>
            <div class="tools p-3 d-flex justify-content-around align-items-center gap-4">
                <div class="skill">
                    <img :src="getLogoPath('Github')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center">Github</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('Figma')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center">Figma</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('WebSockets')" alt="" width="50" height="50">
                    <div class="skill-name d-flex justify-content-center align-items-center">Socket.io</div> 
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="d-flex justify-content-center gap-4 align-items-center mt-5">
            <div class="d-flex align-items-center"> Beginner</div>
            <div class="d-flex align-items-center"> Intermediate</div>
            <div class="d-flex align-items-center"> Proficient</div>
    </div> -->
</template>

<script>
// import {defineVueComponent} from 'vue';

export default {
    name: "SkillSection",
    methods: {
        getLogoPath(logo) {
            return require(`@/assets/svg/skills/${logo}.svg`);
        }
    }
}

</script>

<style>

.status {
    font-size: 10px;
    
}

.proficient {
    color: #41B06E;
}

.intermediate {
    color: #FF885B
}

.beginner {
    color: #7695FF;
}
</style>