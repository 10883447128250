<template>

    <div class="d-flex flex-column flex-md-row justify-content-center gap-4 align-items-center mt-4 mt-lg-4">
        <!-- Exp timeline -->
        <div class="comp-wrapper">
            <div v-for="company in companies" :key="company" class="company">
                {{  company }}
            </div>
            <div class="d-flex flex-column ms-6 mt-3 gap-3">
            <div v-for="exp in experience[activeCompany]" :key="exp.role" class="work-exp-wrapper">
                <div class="exp-role" :class="exp.isActive ? 'active-company-role' : ''"><span class="d-flex justify-content-center align-items-center" :style="{visibility: exp.isActive == true ? 'visible' : 'hidden'}"><i  class="fa-solid fa-circle current-role me-2"></i> </span> {{  exp.role }}</div>
                <div class="exp-date py-1"> {{ exp.dateJoined }}</div>
            </div>
        </div>
        </div>

        
        

        <!-- Work svg -->
        <object :data="workSvg" type="image/svg+xml" class="work-exp-svg d-none d-md-block"></object>
        <!-- Key highlights -->
        <div class="key-highlights p-3">
            <div class="key-title">KEY HIGHLIGHTS AT MICROCHIP</div>
            <!-- <div class="divider"></div> -->
            <div class="mt-3">
                <ul>
                    <li><CircleCheckBig :size="16"/> Developed MyMicrochip Dashboard (Vue 3).</li>
                    <li><CircleCheckBig :size="16"/> Built a personalized email system integrated inside Dashboard.</li>
                    <li><CircleCheckBig :size="16"/> Optimized UI with A/B testing, driving higher user adoption.</li>
                    <li><CircleCheckBig :size="16"/> Created a Product Advisor Tool for filtering 100K+ products.</li>
                    <li><CircleCheckBig :size="16"/> Developed shorthand URL redirect application and .NET APIs.</li>
                    <li><CircleCheckBig :size="16"/> Designed Figma prototypes and developed a Vue UI component library.</li>
                    <li><CircleCheckBig :size="16"/> Integrated Vite for faster builds and simplified vue code flow.</li>
                    <li><CircleCheckBig :size="16"/> Built CI/CD pipeline for the Vue project.</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
// import {defineVueComponent} from 'vue';
import workExp from '@/assets/svg/work-exp.svg'
import { CircleCheckBig } from 'lucide-vue-next';

export default {
    name: "WorkSection",
    components: {
        CircleCheckBig
    },
    data() {
        return {
            workSvg: workExp,
            companies: ['Microchip Technology'],
            activeCompany: "Microchip Technology",
            experience: {
                "Microchip Technology" : [
                    {
                    role: 'Software Engineer 2',
                    dateJoined: 'March 2025',
                    companyLogo: 'mchp.svg',
                    isActive: true
                },
                {
                    role: 'Software Engineer 1',
                    dateJoined: 'July 2023 - March 2025',
                    companyLogo: 'mchp.svg',
                    isActive: false
                },
                {
                    role: 'Software Engineer Intern',
                    dateJoined: 'January 2023 - July 2023',
                    companyLogo: 'mchp.svg',
                    isActive: false
                }
                ]
            }
        }
    }
}

</script>